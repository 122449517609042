import React from "react"

import Header from "components/Header"
import SEO from "components/SEO"

const Courses = () => {
  return (
    <div>
      <SEO />
      <Header />
      <h1>Courses</h1>
    </div>
  )
}

export default Courses
